import React from 'react';
import ReactDOM from 'react-dom'
import L from 'leaflet';

//import 'leaflet/dist/leaflet.css';  //default marker se ni kazal, če je bilo to vključeno tukaj

import 'leaflet-control-geocoder';

import 'leaflet-control-geocoder/dist/Control.Geocoder.css';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

import globals from '../globals';

class Map extends React.Component {
  componentDidMount() {
    // create map
    this.map = L.map('map', {
      center: {lat: 44.08758502824518, lng: 10.865478515625},
      zoom: 7,
      zoomControl: false
    });

    window.map = this.map;

    const baseMaps = {
      "CartoDB.PositronNoLabels": L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}{r}.png', {
	      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
	      subdomains: 'abcd',
	      maxZoom: 19
      }),
      "OpenStreetMap": L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }),
      "DARE": L.tileLayer('https://dh.gu.se/tiles/imperium/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://dh.gu.se/dare/">DARE</a>y'
      })
    };

    const mbAttr = 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';

    const mbUrl = 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';

    baseMaps['ESRI World Imagery'] = L.tileLayer(mbUrl, {
      id: 'esri',
      attribution: mbAttr
    });

    const overlays = {};

    /*
    const overlays = {
      "Barrington Atlas":  L.tileLayer.wms("http://178.238.236.24/geoserver/gi/wms?", {
        layers:"gi:ister_ba_roads",
        format: "image/png",
        transparent: "true",
        attribution: '<a href="http://awmc.unc.edu/">Ancient World Mapping Center</a>, and <a href="https://www.worldcat.org/title/barrington-atlas-of-the-greek-and-roman-world/oclc/43970336">Talbert et al. 2000.</a>'
      }),
      "Roman routes for Slovenia": L.tileLayer.wms("http://178.238.236.24/geoserver/gi/wms?", {
        layers:"gi:ister_linijski_objekti",
        format: "image/png",
        transparent: "true",
        attribution: '<a href="https://mgml.si">The Museum and Galleries of Ljubljana</a>'
      })
    };
    */

    L.control.scale({position: 'bottomright'}).addTo(this.map);

    L.control.layers(baseMaps, overlays).addTo(this.map);

    baseMaps['CartoDB.PositronNoLabels'].addTo(this.map);

    L.control.zoom({
      position:'bottomright'
    }).addTo(this.map);

    let map = this.map;

    L.Control.geocoder({
      geocoder: L.Control.Geocoder.nominatim(),
      defaultMarkGeocode: false
    }).on('markgeocode', function(e) {
      var bbox = e.geocode.bbox;
      var poly = L.polygon([
        bbox.getSouthEast(),
        bbox.getNorthEast(),
        bbox.getNorthWest(),
        bbox.getSouthWest()
      ]);
      map.fitBounds(poly.getBounds());
      map.openPopup(e.geocode.name, e.geocode.center);
    }).addTo(map);

    //make custom controls if defined in props.children
    let leafletControlComponents = React.Children.toArray(this.props.children).filter(x => x.props.leafletControl === true)

    leafletControlComponents.map(x => this.createCustomControl(x))

    this.props.setReference( this.map );

    globals.map = this.map;

    const adminKey = sessionStorage.getItem('adminKey');

    if (adminKey) {
      map.pm.addControls({  
        position: 'topright',  
        drawCircle: false,  
        drawCircleMarker: false,
        drawPolygon: false,
        drawRectangle: false,
        rotateMode: false,
        removalMode: false,
        cutPolygon: false
      }); 
  
      map.on('pm:create', (e) => {
        map.pm && map.pm.disableDraw();
        this.props.onLayerCreate && this.props.onLayerCreate(e);
      });
    }
  }

  createCustomControl(control) {
    var customControl = L.Control.extend({
      options: {
        position: 'topleft' 
          //control position - allowed: 'topleft', 'topright', 'bottomleft', 'bottomright'
        },
        onAdd: function (map) {
          var container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
          return container;
        },
    });

    if (control.props.name === 'DrawerToggler') {
      control = React.cloneElement(control, {'toggleDrawerState': this.props.toggleDrawerState});
    }

    let toggler=new customControl();
    this.map.addControl(toggler);
    let container = toggler.getContainer();
    ReactDOM.render(control,container)
    //ReactDOM.render(<DrawerToggler/>,container) //http://www.deadcoderising.com/react-16-render-a-child-component-into-another-dom-node-using-portals/
    //https://stackoverflow.com/questions/46393642/how-to-use-reactdom-createportal-in-react-16
  }

  render() {
    return <div id="map" style={{height:'100vh'}}></div>
  }
}
export default Map;