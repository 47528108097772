const settings = {
    local: {
        photoStorage: 'http://localhost:8082/ister-server/storage/app/photos/',
        apiUrl: `http://localhost:8082/ister-server/public/api/`,
        email: "",
        password: ""
    },
    server: {
        photoStorage: window.location.origin + '/backend/ister-server/storage/app/photos/',
        apiUrl: window.location.origin + '/backend/ister-server/public/api/',
        email: '',
        password: ''
    }
}

export default settings;