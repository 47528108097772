import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import DenseTable from './DenseTable';

import ComboBox from './ComboBox';

import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

export default function SpatialFilterDialog(props) {

  const classes = useStyles();

  const initialFilter = props.filter || [];
  
  const [filterData, setFilterData] = React.useState(
    initialFilter
  );
  const [filterDataRow, setFilterDataRow] = React.useState({value:''});

  //https://stackoverflow.com/questions/59813926/usestate-to-update-multiple-values-in-react


  const onChange = (what, value) => {
    console.log('onChange', what, value);

    if (what === 'attribute') {
      value = value.id;
    }
    
    setFilterDataRow(Object.assign({}, filterDataRow, {[what]: value}));

  }

  const onRemoveFilterDataRow = (inx) => {
    filterData.splice(inx,1);
    setFilterData([...filterData]);
  }

  const addFilterData = () => {
    setFilterData([...filterData, filterDataRow]);
    setFilterDataRow({value:''});
  }

  if (!props.featureNames || props.featureNames.length===0) return null;  

  return (
    <div>
      <Dialog fullWidth maxWidth='md' open={true} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Spatial filter (applies to all the layers)
        <IconButton onClick={props.handleClose} className={classes.closeButton}><CloseIcon></CloseIcon></IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{width:'50%'}}>Show only those features that are located</div>
            <TextField style={{width: '50%'}} 
              size="small"
              label="Distance"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">meters</InputAdornment>,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={filterDataRow.value}
              onChange={e=>onChange('value', e.target.value)}
            ></TextField>

            <ComboBox value={filterDataRow.condition} onChange={value=>onChange('condition', value)} style={{"margin":'10px'}} items={['or less', 'or more']} label="Select condition"></ComboBox>

            <div>from</div>
            
            <ComboBox value={filterDataRow.attribute} onChange={value=>onChange('attribute', value)} style={{"margin":'10px'}} items={props.featureNames} label="Select spatial feature name"></ComboBox>
          <Button onClick={addFilterData} color="primary" disabled={!(filterDataRow.attribute && filterDataRow.condition && filterDataRow.value)}>Add</Button>
          </div>
          
          <DenseTable columns={[
            {label: 'Distance (m)', key: 'value'},
            {label: 'Condition', key: 'condition'},
            {label: 'Spatial feature name', key: 'attribute', values:props.featureNames}
          ]} onRemoveFilterDataRow={onRemoveFilterDataRow} rows={filterData}></DenseTable>
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>setFilterData([])} color="primary">
            Clear
          </Button>
          <Button onClick={()=>props.onFilterApply(filterData)} color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
