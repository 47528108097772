import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ComboBox(props) {
  const [value, setValue] = React.useState(props.value);

  const items = props.items || (props.attributeDefinition && props.attributeDefinition.values) || [];

  const onChange = (event, newValue) => {
    console.log('onChange', newValue)
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  }

  let element = <Autocomplete
    size="small"
    value={(props.value && value) || ''}
    onChange={onChange}
    id="combo-box-demo"
    options={items}
    getOptionLabel={(option) => option.value || option.en || option.key || option}
    style={{ margin:'10px', width:'100%' }}
    renderInput={(params) => <TextField {...params} label={props.label} variant="outlined" />}
  />;

  if (props.attributeDefinition) {
    const dataType = props.attributeDefinition.dataType;
    if (dataType !== 'code_list') {
      element = <TextField value={(props.value && value) || ''} onChange={e=>onChange(e, e.target.value)} style={{ margin:'10px', width:'100%' }} size="small" label={props.label} variant="outlined"></TextField>;
    }
  }

  return (element
  );
}
