import React from 'react';

import GeneralDialog from './GeneralDialog';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import TextField from '@material-ui/core/TextField';

import axios from 'axios';

export default function AddSpatialFeature(props) {

    const adminKey = sessionStorage.getItem('adminKey');

    const [layerKey, setLayerKey] = React.useState(null);

    const [coordinates, setCoordinates] = React.useState(props.item && props.item.shape === 'Marker' ? props.item.layer.getLatLng() : {});

    const rejectItem = () => {
        props.item.layer.remove();
        props.onClose();
    }

    const acceptItem = () => {

        if (!layerKey) {
            alert("You have to select a layer for the geometry!");
            return;
        }

        props.item.shape === 'Marker' && props.item.layer.setLatLng(coordinates);

        postGeometry(layerKey);
    }

    const postGeometry = (layerKey) => {
        axios.post('/upsert_geometry'+'?key='+adminKey, {
            g: props.item.layer.toGeoJSON(),
            layer_key: layerKey
          }).then(res=> {
            res.data.layer_key = layerKey;
            res.data.layer = props.item.layer;
            props.onSpatialFeatureAdded(res.data);
            props.onClose();
        });
    }

    const handleChange = e => {
        setLayerKey(e.target.value);
    }

    return <GeneralDialog title="Add new item to the ISTER Atlas" onClose={rejectItem} button={
        [
            {
                label: 'Add this item to the selected layer',
                onClick: () => {
                    acceptItem();
                }
            },
            {
                label: 'Reject this item',
                onClick: () => {
                    rejectItem();
                }
            }
        ]
    }> 

    <FormControl style={{width:'50%'}}>
    <InputLabel id="demo-simple-select-label">Select a layer to which this geometry is to be added</InputLabel>
        <Select onChange={handleChange} value={layerKey || ''}>    
            <MenuItem value="monuments">Monuments</MenuItem>
            <MenuItem value="roads">Roman roads</MenuItem>
            <MenuItem value="sites">Roman sites</MenuItem>
            <MenuItem value="milestones">Milestones</MenuItem>
            <MenuItem value="events">Events</MenuItem>
        </Select>
        
        {
            props.item && props.item.shape === 'Marker' && <div>
                <br></br>
                <hr></hr>
                <div>
                    Here you can change the coordinates of your point:
                </div>
                <TextField
                id="standard-number"
                label="Longitude"
                onChange={e => setCoordinates({
                    lat:coordinates.lat,
                    lng:e.target.value
                })}
                value={coordinates.lng}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                />
                <TextField
                id="standard-number"
                label="Latitude"
                onChange={e => setCoordinates({
                    lat:e.target.value,
                    lng:coordinates.lng
                })}
                value={coordinates.lat}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                />
            </div>
        }
    </FormControl>

    </GeneralDialog>;
}