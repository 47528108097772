import React from 'react';
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

import s from '../settings';

const apiServer = process.env.NODE_ENV === 'production' ? 'server' : 'local';

const photoStorage = s[apiServer].photoStorage;

let images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];

export default function ImageGalleryComponent(props) {
  if (!props.images || !Array.isArray(props.images)) return null;
    const selectedLanguage = sessionStorage.getItem('language');
    
    let local = false;
    if (selectedLanguage === props.localLanguage) {
      local = true;
    }

    const images=props.images.map(img=>{
      return {
        original: `${photoStorage}_${img.basename}.jpg`,
        thumbnail:`${photoStorage}_${img.basename}.jpg`,
        description: (local && img.local_desc) || img.desc
      }
    });
    return <ImageGallery items={images} />;
}