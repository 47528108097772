import React, {createRef, useEffect} from 'react';
import GeneralDialog from './GeneralDialog';

import Divider from '@material-ui/core/Divider'

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'

import './dropzone.css';

import s from '../settings';

import axios from 'axios';

import utils from '../utils'

import { Container, Draggable } from 'react-smooth-dnd';

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import SnackMsgBox from './SnackMsgBox';

const descriptions = {};
const localDescriptions = {};

const applyDrag = utils.applyDrag;

window.descriptions = descriptions;
window.localDescriptions = localDescriptions;

const apiServer = process.env.NODE_ENV === 'production' ? 'server' : 'local';

const apiUrl = s[apiServer].apiUrl;
const photoStorage = s[apiServer].photoStorage;

const adminKey = sessionStorage.getItem('adminKey');

const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles }, onFileDropped, dropzoneFileIds, fid }) => {
  
  const sortedPreviews = [];

  const previewsByFileId = {};

  previews.map(preview => previewsByFileId[preview.key] = preview);

  const [_descriptions, setDescriptions] = React.useState(descriptions);
  const [_localDescriptions, setLocalDescriptions] = React.useState(localDescriptions);

  dropzoneFileIds.map(id => {
    sortedPreviews.push(previewsByFileId[id]);
  });

  const onDescriptionChange = (e, key, local=false) => {
    if (local) {
      localDescriptions[key] = _localDescriptions[key] = e.target.value;
      setLocalDescriptions({..._localDescriptions});
    }
    else {
      descriptions[key] = _descriptions[key] = e.target.value;
      setDescriptions({..._descriptions});
    }
    
  }

  return (
    <div>
      <Container onDrop={ e => {
          onFileDropped(files, e);
      }}>
        {sortedPreviews.map((preview, index) => (
          <Draggable key={`item-${index}`}>
            {preview}
            {fid!==-1 && <TextareaAutosize key={`desc-${preview.key}`} value={_descriptions[preview.key] || undefined} onChange={(e) => {onDescriptionChange(e, preview.key); }} style={{width:'100%'}} minRows={1} placeholder="Here you can add a description for this image" />}
            {fid!==-1 && <TextareaAutosize key={`desc-local-${preview.key}`} value={_localDescriptions[preview.key] || undefined} onChange={(e) => {onDescriptionChange(e, preview.key, true); }} style={{width:'100%'}} minRows={1} placeholder="Here you can add a local translation of the description for this image" />}
            <Divider></Divider>
          </Draggable>
        ))}
      </Container>
      
      <div {...dropzoneProps} style={files.length > 0 ? {display:'none'} : {}}>
        {files.length < maxFiles && input}
      </div>

      {/*files.length > 0 && submitButton*/}
    </div>
  )
}

const MyUploader = React.forwardRef((props, dropzoneRef) => {
    // specify upload params and url for your files
    const getUploadParams = ({ meta, file }) => { 
      
      return { url: file.isInitial ? window.location.origin : apiUrl + `upload_resources?id=${props.id}&file_key=${meta.id}&key=${adminKey}&tname=${props.tname}`, method:  file.isInitial  ? 'GET' : 'POST' }
    }

    const {dropzoneFileIds, setDropzoneFileIds} = props;

    const initialFiles = props.loadedInitialFiles;
    const setInitialFiles = props.setLoadedInitialFiles;
    
    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => { 
      //console.log(status, meta, file, dropzoneRef);
      
      if (status === 'removed') {
        const index = dropzoneFileIds.indexOf(meta.id);
        dropzoneFileIds.splice(index, 1);
        setDropzoneFileIds([...dropzoneFileIds]);
      }
      else if (status === 'done') {

        dropzoneFileIds.push(meta.id);

        console.log(meta, file)

        if (file.isInitial) {//loading initial files
          console.log('loading initial files')
          initialFiles.push(meta.id);
          // handleChangeStatus 'done' is not called synchronously
          dropzoneFileIds.sort();
          initialFiles.sort();

          if (initialFiles.length === props.initialFiles.length) {//loading initial files is completed
            initialFiles.map((fileId, inx) => {
              descriptions[fileId] = descriptions[inx];
              localDescriptions[fileId] = localDescriptions[inx];
              delete descriptions[inx];
              delete localDescriptions[inx];
            });
          } 

          setInitialFiles([...initialFiles]);
        }

        setDropzoneFileIds([...dropzoneFileIds]);

      }
    }
    
    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
      allFiles.forEach(f => f.remove())
    }

    const onFileDropped = (files, e) => {
      setDropzoneFileIds(applyDrag(dropzoneFileIds, e));
    }
  
    return (
      <Dropzone
        ref={dropzoneRef}
        getUploadParams={ getUploadParams }
        onChangeStatus={handleChangeStatus}
        //LayoutComponent={Layout}
        LayoutComponent={(p)=><Layout {...p} fid={props.id} onFileDropped={onFileDropped} dropzoneFileIds={dropzoneFileIds} ></Layout>}
        //onSubmit={handleSubmit}
        //accept="image/*,audio/*,video/*"
        //accept="image/*"
        accept="image/jpeg,image/png"
        maxFiles={props.id===-1 ? 1 : 1024}
        inputContent={props.id===-1?"Drop a file":'Drag Files or Click to Browse'}
        initialFiles={props.initialFiles}
        multiple={props.id===-1 ? false : true}
      />
    )
  });

export default function ImageDialog(props) {

    const [dropzoneFileIds, setDropzoneFileIds] = React.useState([]);
    const [initialFiles, setInitialFiles] = React.useState([]);
    const [loadedInitialFiles, setLoadedInitialFiles] = React.useState([]);

    const onAddFiles = () => {
      
        dropzoneRef.current.dropzone.current.getElementsByTagName('input')[0].click();
        
    }

    const saveImages = () => {
      const fid = props.featureProperties ? props.featureProperties.id : -1;
      axios.post('/save_images/'+fid+'?key='+adminKey, {
        descriptions: descriptions,
        localDescriptions: localDescriptions,
        fileIds: dropzoneFileIds,
        existingFiles: loadedInitialFiles
      }).then(res=> {
        setLoadedInitialFiles([...dropzoneFileIds]);
        props.onSaveComplete('images',fid,res.data);
      });
    }

    const dropzoneRef = createRef();

    useEffect(() => {

      const images = props.featureProperties ? props.featureProperties.images : (props.images || []);

      if (images.length === 0) return;

      async function fetchData() {

        axios.all(images.map(img=>`${photoStorage}_${img.basename}.jpg`).map((endpoint) => axios.get(endpoint,{
          responseType: 'blob'
        }))).then(
          axios.spread((...allData) => {
            
            const currentImageFiles = [];

            for (const [i,response] of allData.entries()) {
              
              //const blob = await response.data.blob();
              const blob = response.data;
              const image = images[i];
              console.log('image', image)
              const nf = new File([blob], image.fname, { type: blob.type });
              nf.isInitial = true;
              currentImageFiles.push(nf);
            }

            setInitialFiles([...currentImageFiles]);
          })
        );
      }

      for (const [i,image] of images.entries()) {
        descriptions[i] = image.desc;
        localDescriptions[i] = image.local_desc;
      }
      
      fetchData();
      
    },[]);

  const fid = props.featureProperties ? props.featureProperties.id : -1;

  return (
    <GeneralDialog title={props.featureProperties ? props.featureProperties.name : 'Upload title image'} onClose={props.handleClose} button={[{onClick:onAddFiles, label:fid==-1 ? 'Add a file' : 'Add files'}, {onClick:saveImages, label:'Save'}]}>
      <MyUploader id={fid} ref={dropzoneRef} 
        tname={props.tname}
        dropzoneFileIds={dropzoneFileIds} 
        setDropzoneFileIds={setDropzoneFileIds} 
        initialFiles={initialFiles}
        loadedInitialFiles = {loadedInitialFiles}
        setLoadedInitialFiles = {setLoadedInitialFiles}
      />
    </GeneralDialog>
  );
}
