import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import GridIcon from '@material-ui/icons/GridOn';
import FilterListIcon from '@material-ui/icons/FilterList';
import { MuiThemeProvider  } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const styles = theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  bold: {
    fontWeight:'bold'
  },
  badge: {
    backgroundColor: '#777'
  },
  filterOn: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    color: '#fff'
  }
});

const theme = createTheme({
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        //color:'black',
        '&$checked': { // <-- mixing the two classes
          color: 'rgba(0, 0, 0, 0.87)!important',
        }
      }
    },
    MuiList: {
     padding: {
        paddingTop:0,
        //paddingBottom:0
      }
    },
    MuiListItem: {
      secondaryAction: {
        paddingRight: '12px'
      },
      dense: {
        paddingTop:0,
        paddingBottom:0
      }
    },
    MuiListItemText: {
      root: {
        paddingRight:'20px'
      }
    },
    MuiIconButton:
    {
      root: {
        padding: '5px'
      }
    }
  }
})

class CheckboxList extends React.Component {
  state = {
    open: true
  };

  render() {
    const { classes } = this.props;
    
    const ListItems = function(props) {
      return (<List>
              {props.items && props.items.map(item => {
                const data = this.props.layersData && this.props.layersData[item.key];

                item.record_count = data && data.data && data.data.length;
                
                const checked = props.selectedLayers[item.key] === true;
                const _disabled = !props.parentChecked || !checked || item.record_count===0;
                return (
                
                <ListItem key={item.key} role={undefined} dense button>
                  <Checkbox
                    checked={checked}
                    disabled={!props.parentChecked || item.record_count===0}
                    tabIndex={-1}
                    disableRipple
                    onClick={props.handleToggle(item, checked)} 
                  />
                  {item.additional && item.additional.icon && <IconButton><img src={'./icons/'+item.additional.icon}/></IconButton>}
                  <ListItemText primary={item.label} />
                  {item.img && <img src={item.img}/>}
                  <Badge max={99999} disabled={_disabled} onClick={() => !_disabled && this.props.handleLayerAttributes(item)} classes={{ badge: classes.badge }} showZero={true} badgeContent={item.record_count} color="primary"></Badge>
                  <IconButton disabled={_disabled} aria-label="grid" onClick={() => this.props.handleLayerAttributes(item)}><GridIcon/></IconButton>
                  <IconButton disabled={_disabled} onClick={() => this.props.handleLayerFilter(item)}><FilterListIcon className={item.filter && item.filter.length>0 ? classes.filterOn : null}></FilterListIcon></IconButton>
                </ListItem>
                
              )})}
        </List>)
    }.bind(this);
    
    return <MuiThemeProvider theme={theme}>
            <ListItems handleToggle={this.props.handleToggle} selectedLayers={this.props.selectedLayers} items={this.props.items} parentChecked={true}></ListItems>
          </MuiThemeProvider>
  }
}

CheckboxList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CheckboxList);