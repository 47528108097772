import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';

const styles = theme => ({
    close: {
      padding: theme.spacing(1/2),
    },
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

class SnackMsgBox extends React.Component {
    
    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.onClose && this.props.onClose();
    };

    render() {
        const { classes } = this.props;
        if ( this.props.msg === null ) return null; //react way je "state should be managed in the parent component"
        return (
            this.props.severity ? 
            <Snackbar open={true} autoHideDuration={5000} onClose={this.handleClose}>
                <Alert onClose={this.handleClose} severity={this.props.severity}>
                    {this.props.msg}
                </Alert>
            </Snackbar>
            :
        <Snackbar
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    open={true}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                    ContentProps={{
                    'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.props.msg}</span>}
                    action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                    ]}
                />);
    }
}

SnackMsgBox.propTypes = {
    classes: PropTypes.object.isRequired,
  };
  
export default withStyles(styles)(SnackMsgBox);