import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import axios from 'axios';
import SnackMsgBox from './SnackMsgBox';
import {mapUtils} from '../utils';
import CheckboxList from './CheckboxList';

import Portal from '@material-ui/core/Portal';

import ImageGallery from './ImageGallery';

import FullScreenDialog from './FullScreenDialog';
import FilterDialog from './FilterDialog';
import SpatialFilterDialog from './SpatialFilterDialog';
import LinearProgress from '@material-ui/core/LinearProgress';

import AttributeEditor from './AttributeEditor';

import FilterListIcon from '@material-ui/icons/FilterList';

import Button from '@material-ui/core/Button';

import ImageDialog from './ImageDialog';
import LinkDialog from './LinkDialog';

import LinkGallery from './LinkGallery';

import globals from '../globals';

import utils from '../utils';

import { Alert, AlertTitle } from '@material-ui/lab';
import GeneralDialog from './GeneralDialog';

import s from '../settings';

const apiServer = process.env.NODE_ENV === 'production' ? 'server' : 'local';

const photoStorage = s[apiServer].photoStorage;

const styles = theme => ({
  bar2Indeterminate: {
    animation: 'none'
  },
  bar1Indeterminate: {
    animation: 'none'
  },
  padding: {
    paddingBottom: theme.spacing(1)
  },
  filterOn: {
    backgroundColor: 'rgba(0, 0, 0, 0.54)',
    color: '#fff'
  }
});

function getSelectedLayerKey (key) {
  
  if (['milestones','events'].indexOf(key)===-1) {
    return 'ister_layers';
  }
  else {
    return key;
  }
}

class LayerSelector extends React.Component {
  state = {
    loading: false,
    imageDialogFeatureProperties: null,
    linkDialogFeatureProperties: null,
    attributeEditorDialog: null,
    errorMsg: null,
    dialogOpen: false,
    filterDialogOpen: false,
    spatialFilterDialogOpen: false,
    selectedLayerKey: null,
    sharedInfo:'',
    sharedTitle:'',
    spatialFilter:[],
    selectedLayers:{},
    sharedImg:'',
    saveStatusMsg:null
  };

  layers={};

  drawMarkers = {};
  
  layerListItems = [];

  attributes = [];
  assocAttributes = {};

  /**
   * Here I add current data and assocAttributes to the selected layer to be used in a grid
   * @param {*} layer 
   * @returns 
   */

  handleLayerAttributes = (layer) => {
    const layerId = layer.key;
    const data = this.state.layersData[layerId].data;

    if (!data) return;

    this.setState({
      selectedLayerKey: layerId,
      dialogOpen: true
    });
  }

  onFilterApply(filter, layerKey=null) {
    
    const selectedLayerKey = layerKey || this.state.selectedLayerKey;
    if (!selectedLayerKey) return;
    
    axios.post('/layers_data/'+selectedLayerKey, {
      filter: filter,
      spatial_filter: this.state.spatialFilter
    }).then(res=> {
      
      globals.filter[selectedLayerKey] = filter;

      this.state.layersData[selectedLayerKey] = {
        data:res.data,
        filter:filter
      }

      const filteredLayer = this.layerListItems.find(item => item.key === selectedLayerKey);

      filteredLayer.filter = filter;
      filteredLayer.record_count = res.data.length;

      this.setState({layersData: {...this.state.layersData}},()=>{

        const mapLayer = this.layers[selectedLayerKey];
        const map = this.props.leafletMap();

        if (mapLayer && map.hasLayer(mapLayer)) {
          map.removeLayer(mapLayer);
          this.addLayerToMap(filteredLayer);
        }
        else {
          delete this.layers[selectedLayerKey];
        }

      });
    });
  }

  onSpatialFilterApply(filter) {
    this.setState({spatialFilter: filter}, ()=>{
      globals.spatialFilter = [...filter];
      this.layerListItems.map(layerItem => { 
        this.onFilterApply(globals.filter[layerItem.key], layerItem.key);
      });
    });
  }

  handleLayerFilter = (layerMenuItem) => {
    
    this.setState({
      selectedLayerKey: layerMenuItem.key,
      filterDialogOpen: true
    });
  }

  handleSave = () => {
    if (this.state.selectedLayerKey === null) return;
    const layer = this.layers[this.state.selectedLayerKey]
    if (!layer) return;

    var obj = layer.toGeoJSON();
    var data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(obj));
    return data;
  }

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleFilterDialogClose = () => {
    this.setState({ filterDialogOpen: false });
  }

  onImageDialogIconClicked = (featureProperties) => {
    this.setState({imageDialogFeatureProperties: featureProperties});
  }

  onEditAttributes = (featureProperties, e, renderPopupDiv, entries) => {
    this.setState({attributeEditorDialog: {featureProperties:featureProperties,keyValue: e, renderPopupDiv, entries: [...entries]}});
  }
  
  onLinkDialogIconClicked = (featureProperties) => {
    this.setState({linkDialogFeatureProperties: featureProperties});
  }

  onDeleteFeature = (featureProperties, layer, popup) => {
    if (confirm('Do you really want to delete this feature? The action is irreversible!')) {
      const adminKey = sessionStorage.getItem('adminKey');
      axios.delete(`/delete_geometry/${featureProperties.id}?key=${adminKey}&tname=${getSelectedLayerKey(featureProperties.layer_key)}`, {
      }).then(res=> {
        this.setState({
          saveStatusMsg: `The feature was successfully deleted.`
        });
        
        popup && this.props.leafletMap().closePopup(popup);
        layer && layer.remove();

      });
    }
  }

  addLayerToMap = (layer) => {
    const layerId = layer.key;
    console.log(this.state.layersData)
    const data = this.state.layersData[layerId].data;
    const additional = layer.additional || {};
    const iconImg = additional.useDefaultMarker === true ? null : additional.icon;
    
    const {lineColor, lineWidth, dashArray,  opacity} = layer.additional;

    const addedLayer = this.layers[layerId] = mapUtils.add(
      this.props.leafletMap(), 
      data, 
      iconImg, 
      {lineColor, lineWidth, dashArray,  opacity},
      (latlng, featureId, layer) => this.onFeatureClicked(latlng, featureId, layerId, layer)
    );

    addedLayer.on('pm:edit', (e) => {
      const adminKey = sessionStorage.getItem('adminKey');
      const id = e.layer.feature.geometry.properties.id;
      const g = e.layer.toGeoJSON().geometry;
      const layerKey = e.layer.feature.geometry.properties.layer_key;

      const map = this.props.leafletMap();
      map.closePopup();

      axios.post(`/upsert_geometry/${id}?key=${adminKey}`, {
        g: g,
        layer_key: layerKey
      }).then(res=> {

        const item = this.state.layersData[layerKey].data.find(d => d.id == id);
        if (item) {
          item.country = res.data.country;
        }

        this.setState({
          layersData: {...this.state.layersData},
          saveStatusMsg: `Geometry successfully updated.`
        })
      });
    });
  }

  onFeatureClicked = (latlng, featureId, layerKey, layer) => {
    const featureProperties = this.state.layersData[layerKey].data.find(d => d.id == featureId);
    mapUtils.openPopup(this.props.leafletMap(), latlng, featureProperties, this.assocAttributes[getSelectedLayerKey(layerKey)], {onDeleteFeature: this.onDeleteFeature, onEditAttributes: this.onEditAttributes, onImageDialogIconClicked: this.onImageDialogIconClicked, onLinkDialogIconClicked: this.onLinkDialogIconClicked}, layer)
  }

  onImageDialogClosed = () => {
    this.setState({imageDialogFeatureProperties: null});
  }

  onLinkDialogClosed = () => {
    this.setState({linkDialogFeatureProperties: null});
  }

  onAttributeEditorClosed = () => {
    this.setState({attributeEditorDialog: null});
  }

  onSaveComplete = (key, id, data) => {
    let featurePropertiesStateKey = null;
    
    if (key === 'links') {
      featurePropertiesStateKey = 'linkDialogFeatureProperties';
    }
    else if (key === 'images') {
      featurePropertiesStateKey = 'imageDialogFeatureProperties';
    }
    else if (key) {
      featurePropertiesStateKey = 'attributeEditorDialog';
    }

    if (!featurePropertiesStateKey) return;

    let keyValue = this.state[featurePropertiesStateKey].keyValue;
    let changeOfLocalAttributeValue = false;
    if (keyValue && keyValue.e) {
      keyValue = keyValue.e;
      changeOfLocalAttributeValue = true;
    }

    const featureProperties = featurePropertiesStateKey === 'attributeEditorDialog' ? this.state[featurePropertiesStateKey].featureProperties : this.state[featurePropertiesStateKey];

    const layerId = featureProperties && featureProperties.layer_key;

    if (!layerId) return;
    const _data = this.state.layersData[layerId].data;
    const inx = _data.findIndex(d => d.id == id);
    if (inx===-1) return;

    if (changeOfLocalAttributeValue) {
      featureProperties._locales = Object.assign(utils.jsonParse(featureProperties._locales || {}), {[key]:data});
    }
    else {
      featureProperties[key] = data;
      _data[inx][key] = data;
    }

    const nstate = {
      layersData: {...this.state.layersData},
      saveStatusMsg: `Attribute '${key}' for the selected feature was successfully updated and saved.`
    };

    if (featurePropertiesStateKey === 'attributeEditorDialog') {
      const {renderPopupDiv, entries} = this.state[featurePropertiesStateKey];
      console.log('entries, keyValue, key, data, featureProperties', entries, keyValue, key,data,featureProperties)
      
      if (!changeOfLocalAttributeValue) {
        const item = entries.find(e => key === e[e.length-1]);
        item[1] = data;
      }
      
      renderPopupDiv(entries);
      keyValue[1] = data; //new value
      nstate[featurePropertiesStateKey] = {
        keyValue: [...keyValue], 
        featureProperties: {...featureProperties},
        renderPopupDiv, 
        entries: [...entries]
      };
    }
    else {
      nstate[featurePropertiesStateKey] = {...featureProperties};
    }

    this.setState(nstate);
  }

  handleLayerSelect = (layer, remove) => {

    const layerId = layer.key;

    let self = this;

    const toggleLayers = (layers, map, layer, remove) => {
      const toggleLayer = (layer, remove) => {

        const mapLayer = layers[layer.key];

        if (!mapLayer) return;

        if (remove) {
          map.removeLayer(mapLayer);
          self.setState({selectedLayerKey: null});
        }
        else {
          map.addLayer(mapLayer);
          self.setState({selectedLayerKey: layer.key});
        }
      }
      toggleLayer(layer, remove);
    }
    
    toggleLayers(this.layers, this.props.leafletMap(), layer, remove);

    if (remove || this.layers[layerId] !== undefined) return;

    if (!remove && this.drawMarkers[layer.key]) {
      for(const layer of this.drawMarkers[layer.key]) {
        console.log('f',layer)
        layer.remove();
      }
      this.drawMarkers[layer.key] = [];
    }

    if (layer.items && layer.items.length > 0) return;

    if (self.layers[layerId] === undefined) {
      this.addLayerToMap(layer);
      self.setState({selectedLayerKey: layer.key});
    }
  };

  handleToggle = (item, checked) => () => {
    const selectedLayers = {...this.state.selectedLayers};

    if (selectedLayers[item.key]===true) {
      delete selectedLayers[item.key];
    }
    else {
      selectedLayers[item.key] = true;
    }

    this.setState({selectedLayers: selectedLayers}, ()=>{
      globals.checked = {...selectedLayers};
      this.handleLayerSelect(item, checked);
    });
  };

  componentDidUpdate() {
    this.setLayerListItems();
    if (this.props.addedSpatialFeature && this.props.addedSpatialFeature.layer_key) {

      const layer_key = this.props.addedSpatialFeature.layer_key;

      console.log('layer_key', layer_key, Object.assign({},this.state.layersData[layer_key]), this.props.addedSpatialFeature)

      if (!this.drawMarkers[layer_key]) this.drawMarkers[layer_key] = [];

      this.drawMarkers[layer_key].push(this.props.addedSpatialFeature.layer);

      if (this.state.layersData[layer_key]) {
        
        const d = Object.assign({},this.state.layersData[layer_key].data[0]);

        Object.keys(d).map(key => d[key]=null);

        d.id = this.props.addedSpatialFeature.id;
        d.g = this.props.addedSpatialFeature.g;
        d.layer_key = layer_key;

        d.country = this.props.addedSpatialFeature.country;

        const llitem = this.layerListItems.find(item => item.key===layer_key);

        if (llitem) {
          llitem.record_count++;
        }

        this.state.layersData[layer_key].data.push(d);
        this.setState({layersData: {...this.state.layersData}});

        if (this.layers[layer_key]) {
          const feature = JSON.parse(this.props.addedSpatialFeature.g);
          feature.properties = d;
          this.layers[layer_key].addData(feature);
        }
      }

      this.props.resetAddedSpatialFeature();
    }
    
  }

  setLayerListItems() {
    this.layerListItems = [{
      key: 'monuments',
      label: globals.getTranslation('ui.monuments'),
      additional: {icon: 'rim_steber_ikona-01.svg'}
    },
    {
      key: 'roads',
      label: globals.getTranslation('ui.roman_roads'),
      additional: {"icon":"polyline.svg", "lineColor":"#0b2066"}
    },
    {
      key: 'sites',
      label: globals.getTranslation('ui.roman_sites'),
      additional: {"icon":"site_category.svg"}
    },
    {
      key: 'milestones',
      label: globals.getTranslation('ui.milestones'),
      additional: {"icon":"milestone.svg"}
    },
    {
      key: 'events',
      label: globals.getTranslation('ui.events'),
      //additional: {"useDefaultMarker":true}
      additional: {"icon":"edit-calendar.svg"}
    }];
  }

  componentDidMount() {

    this.setLayerListItems();
    
    let self = this
    this.setState({loading: true});
    const shareId = utils.getQueryParameter('share');
    axios.all([axios.get('/layers_definitions'),axios.get(`/layers_data${shareId ? `?share=${shareId}` : ''}`)])
    .then(axios.spread((layersDefinitionResponse, layersDataResponse) => {

      const layersData = shareId ? layersDataResponse.data.data : layersDataResponse.data;

      const shared = shareId ? layersDataResponse.data.shared : {};

      const _layersData = {};

      self.layerListItems.map(llitem => {
        const key = llitem.key;
        const data = layersData.filter(d => d.layer_key === key);
        const layerItemsCount = data.length;
        llitem.record_count = layerItemsCount;
        _layersData[key] = {
          data: data
        }
      });

      self.featureNames = [];

      self.layerListItems.map(item => {
        const key = item.key;
        _layersData[key].data.map(f => {
          f.name && this.featureNames.push({
            id: f.id, key:f.name
          });
        });
      });

      const selectedLayers = {};

      shareId && self.layerListItems.map(layerItem => {
        layerItem.filter = shared.filter[layerItem.key];
        
        if (shared.checked[layerItem.key]) {
          selectedLayers[layerItem.key] = true;
        }
        
        if (layerItem.filter) {
          globals.filter[layerItem.key] = [...layerItem.filter];
        }
      });

      const assocAttributes = {};

      Object.keys(layersDefinitionResponse.data).map(key=>assocAttributes[key]={});

      Object.keys(layersDefinitionResponse.data).map(key=> layersDefinitionResponse.data[key].map(a => {
        assocAttributes[key][a.key] = a;
        a.t = globals.getTranslation('a.'+a.key);
        if (a.dataType === 'code_list') {
          const tvalues = [];
          a.values.map(value => tvalues.push({key: value, value:globals.getTranslation(a.key + '.' +value)}));
          a.values = tvalues;
        }
      }));

      self.attributes = layersDefinitionResponse.data;
      self.assocAttributes = assocAttributes;

      const state = {
        layersData: _layersData,
        selectedLayers: selectedLayers
      };

      if (shared.info) {
        state.sharedInfo = shared.info;
      }

      if (shared.title) {
        state.sharedTitle = shared.title;
      }

      if (shared.img) {
        state.sharedImg = shared.img;
      }

      if (shared.spatial_filter) {
        state.spatialFilter=shared.spatial_filter;
      }

      self.setState(state);

      if (shareId) {
        
        self.layerListItems.map(layerItem => { 
          selectedLayers[layerItem.key] && this.handleLayerSelect(self.layerListItems.find(item => item.key === layerItem.key));
        });

        const map = this.props.leafletMap();
        map.flyTo(shared.map.center, shared.map.zoom);
      }

    }))
    .catch(function (error) {
      console.log(error);
    }).then(() => //always executed
    this.setState({loading: false})
  );
  }

  render() {
    const { classes } = this.props;

    let selectedLayerKey = this.state.selectedLayerKey ? this.state.selectedLayerKey : null;
    if (selectedLayerKey && selectedLayerKey.key) { //--hack
      selectedLayerKey = selectedLayerKey.key;
    }

    const selectedLayerLabel = selectedLayerKey ? this.layerListItems.find(item => item.key === selectedLayerKey).label : '';

    console.log('----------->', selectedLayerKey, selectedLayerLabel)

    const adminKey = sessionStorage.getItem('adminKey');

    return(
        <div>
           {this.state.saveStatusMsg && <Portal><SnackMsgBox severity="success" onClose={()=>{this.setState({saveStatusMsg:null});}} msg={this.state.saveStatusMsg}></SnackMsgBox></Portal>}
            <LinearProgress 
              style={{marginTop:'-83px', marginBottom:'100px'}}
              classes={
                this.state.loading ? null : {
                  bar1Indeterminate: classes.bar1Indeterminate,
                  bar2Indeterminate: classes.bar2Indeterminate,
                }
              }
            />
            <div className={classes.padding} />
            {
              (this.state.sharedTitle || this.state.sharedInfo) &&
              <Alert severity="info">
                <AlertTitle>{this.state.sharedTitle || 'Info'}</AlertTitle>
                {this.state.sharedImg && <img style={{maxWidth:'100%'}} src={photoStorage + this.state.sharedImg}></img>}
                {this.state.sharedInfo}
              </Alert>

            }
            <CheckboxList handleToggle={this.handleToggle} selectedLayers={this.state.selectedLayers} items={this.layerListItems}  layersData = {this.state.layersData} handleLayerFilter = {(layerId) => this.handleLayerFilter(layerId)} handleLayerAttributes = {(layerId) => this.handleLayerAttributes(layerId)}></CheckboxList>
            <SnackMsgBox severity="error" msg={this.state.errorMsg}></SnackMsgBox>
            {this.state.dialogOpen && selectedLayerKey && <FullScreenDialog label = {selectedLayerLabel} selectedLayerData={this.state.layersData[selectedLayerKey].data} 
              handleSave = {this.handleSave}
              handleClose = {this.handleClose} 
              open = {this.state.dialogOpen}
              attributes = {this.assocAttributes[getSelectedLayerKey(selectedLayerKey)]}
            ></FullScreenDialog>
            }
            {this.state.filterDialogOpen && 
              <FilterDialog attributes={this.attributes[getSelectedLayerKey(selectedLayerKey)]} selectedLayerKey={this.state.selectedLayerKey} onFilterApply = {(filter) => this.onFilterApply(filter)} 
                layersData = {this.state.layersData}
                label = {selectedLayerLabel}
                handleClose = {this.handleFilterDialogClose}
              />
            }
            {
              this.state.spatialFilterDialogOpen && 
              <SpatialFilterDialog filter={this.state.spatialFilter} onFilterApply = {(filter) => this.onSpatialFilterApply(filter)} featureNames={this.featureNames} handleClose = {() => this.setState({spatialFilterDialogOpen: false})}/>
            }
            <Button  style={{position:'absolute', right:0, marginRight:'10px'}} endIcon={<FilterListIcon className={this.state.spatialFilter.length>0 ? classes.filterOn : null}></FilterListIcon>} onClick={()=>this.setState({spatialFilterDialogOpen: true})} variant="outlined" color="primary">
              {globals.getTranslation("ui.Spatial filter")}
            </Button>

            {
              this.state.imageDialogFeatureProperties && (
                adminKey ? 
                  <ImageDialog tname={getSelectedLayerKey(this.state.imageDialogFeatureProperties.layer_key)} onSaveComplete={this.onSaveComplete} handleClose={this.onImageDialogClosed} featureProperties={this.state.imageDialogFeatureProperties}></ImageDialog>
                  :
                  <GeneralDialog onClose={this.onImageDialogClosed} title={this.state.imageDialogFeatureProperties.name}>
                    <ImageGallery localLanguage={utils.getLocalLanguage(this.state.imageDialogFeatureProperties.country)} images={this.state.imageDialogFeatureProperties.images}></ImageGallery>
                  </GeneralDialog>
              )
            }
            {
              this.state.linkDialogFeatureProperties && (
                adminKey ? 
                <LinkDialog tname={getSelectedLayerKey(this.state.linkDialogFeatureProperties.layer_key)} onSaveComplete={this.onSaveComplete} handleClose={this.onLinkDialogClosed} featureProperties={this.state.linkDialogFeatureProperties}></LinkDialog>
                :
                <GeneralDialog onClose={this.onLinkDialogClosed} title={this.state.linkDialogFeatureProperties.name}>
                  <LinkGallery localLanguage={utils.getLocalLanguage(this.state.linkDialogFeatureProperties.country)} items={this.state.linkDialogFeatureProperties.links}></LinkGallery>
                </GeneralDialog>
              )
            }
            {
              this.state.attributeEditorDialog && (
                adminKey && 
                  <AttributeEditor tname={getSelectedLayerKey(this.state.attributeEditorDialog.featureProperties.layer_key)} onClose = {this.onAttributeEditorClosed} onSaveComplete={this.onSaveComplete} attributeProperties = {this.assocAttributes[getSelectedLayerKey(this.state.attributeEditorDialog.featureProperties.layer_key)]} featureProperties={this.state.attributeEditorDialog.featureProperties} keyValue = {this.state.attributeEditorDialog.keyValue}></AttributeEditor>
              )
            }

        </div>
    )
  }

  }

export default withStyles(styles)(LayerSelector);