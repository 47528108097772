import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import DenseTable from './DenseTable';

import globals from '../globals';

import ComboBox from './ComboBox';

import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

export default function FilterDialog(props) {

  const classes = useStyles();

  const layerId = props.selectedLayerKey;

  const initialFilter = props.layersData && props.layersData[layerId] && props.layersData[layerId].filter || [[]];

  const [conditions, setConditions] = React.useState([]);
  const [currentAttributeDefinition, setCurrentAttributeDefinition] = React.useState();
  //filter={props.selectedLayer && props.selectedLayer.filter}
  const [filterData, setFilterData] = React.useState(
    initialFilter
  );
  const [filterDataRow, setFilterDataRow] = React.useState({});

  if (!props.selectedLayerKey) return null;  

  //https://stackoverflow.com/questions/59813926/usestate-to-update-multiple-values-in-react

  
  const onDataTypeChange = (attributeDefinition) => {
    setCurrentAttributeDefinition(attributeDefinition);
    
    if (!attributeDefinition) {
      setConditions([]);
      return;
    }

    const dataType = attributeDefinition.dataType;
    if (dataType === 'text') {
      setConditions([
        'like',
        'not like'
      ])
    }
    else if (dataType === 'code_list') {
      setConditions(['=', '<>']);
    }
  }

  const onChange = (what, value) => {
    if (what === 'attribute') {
      onDataTypeChange(value);
      if (value === null) return;
      value = value.key;
    }
    else if (value.key) {
      value = value.key;
    }
    
    setFilterDataRow(Object.assign({}, filterDataRow, {[what]: value}));

  }

  const onRemoveFilterDataRow = (inx, tableId) => {
    filterData[tableId].splice(inx,1);
    setFilterData(JSON.parse(JSON.stringify(filterData)));
  }

  const addFilterData = () => {
    filterData[filterData.length-1].push(filterDataRow);
    setFilterData(JSON.parse(JSON.stringify(filterData)));
    setFilterDataRow({});
  }

  const addFilterGroup = () => {
    filterData.push([]);
    setFilterData(JSON.parse(JSON.stringify(filterData)));
    setFilterDataRow({});
  }

  const removeFilterDataGroup = (inx) => {
    filterData.splice(inx,1);
    setFilterData(JSON.parse(JSON.stringify(filterData)));
  }

  if (!props.selectedLayerKey) return null;

  const attributes = props.attributes;

  if (!attributes || attributes.length===0) return null;

  console.log('filterData', filterData)

  return (
    <div>
      <Dialog fullWidth maxWidth='md' open={true} onClose={props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{props.label}
        <IconButton onClick={props.handleClose} className={classes.closeButton}><CloseIcon></CloseIcon></IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <div style={{display: 'flex'}}>
          <ComboBox style={{"margin":'10px'}} value={filterDataRow.attribute} onChange={(value) => onChange('attribute', value)} items={attributes.filter(a => a.t || a.en)} label={globals.getTranslation('ui.filter.Select layer attribute')}></ComboBox>
          <ComboBox style={{"margin":'10px'}} value={filterDataRow.condition} onChange={(value) => onChange('condition', value)} items={conditions || []} label={globals.getTranslation('ui.filter.Select condition')}></ComboBox>
          <ComboBox style={{"margin":'10px'}} value={filterDataRow.value} onChange={(value) => onChange('value', value)} attributeDefinition = {currentAttributeDefinition} label={globals.getTranslation('ui.filter.Attribute value')}></ComboBox>
          <Button onClick={addFilterData} color="primary" disabled={!(filterDataRow.attribute && filterDataRow.condition && filterDataRow.value)}>{globals.getTranslation('ui.filter.ADD')}</Button>
          </div>
          {
            filterData.map((filterDataGroup, inx) =>
            <div>
              <IconButton style={{float:'right'}} onClick={() => removeFilterDataGroup(inx)}><DeleteIcon></DeleteIcon></IconButton>
              <DenseTable key={`filter-${inx}`} onRemoveFilterDataRow={(rowInx) => onRemoveFilterDataRow(rowInx, inx)} rows={filterDataGroup}></DenseTable>
            </div>
            )
          }
          
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>addFilterGroup()} color="primary">
            {globals.getTranslation('ui.filter.Add new filter group')}
          </Button>
          <Button onClick={()=>setFilterData([[]])} color="primary">
            {globals.getTranslation('ui.filter.Clear all')}
          </Button>
          <Button onClick={()=>props.onFilterApply(filterData)} color="primary">
            {globals.getTranslation('ui.filter.Apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
