import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles';

import { Container, Draggable } from 'react-smooth-dnd';

import DeleteIcon from '@material-ui/icons/Delete';

import GeneralDialog from './GeneralDialog';

import axios from 'axios';

import utils from '../utils'

const adminKey = sessionStorage.getItem('adminKey');

const applyDrag = utils.applyDrag;
  
const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
  }));

export default function LinkDialog(props) {
    
    const classes = useStyles();

    const [items,setItems] = React.useState(props.featureProperties.links || []);

    const handleChange = (event, index, key) => {
      items[index][key] = event.target.value;
      setItems([...items]);
    };

    const onDrop = e => {
      setItems(applyDrag(items, e))
    }

    const onDelete = index => {
      items.splice(index,1);
      setItems([...items]);
    }

    const doesImageExist = (url) =>
      new Promise((resolve) => {
        const img = new Image();

        img.src = url;
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
    });

    const saveLinks = async () => {

      for(const item of items) {
        const res = await doesImageExist(item.url);
        item.image = res;
      }

      axios.post('/save_links/'+props.featureProperties.id+'?key='+adminKey, {
        links: items,
        tname: props.tname
      }).then(res=> {
        props.onSaveComplete('links',props.featureProperties.id,res.data);
      });
    }

    const onAddLink = () => {
      items.push({url:'',desc:'',local_desc:''});
      setItems([...items]);
    }

  return (
      <GeneralDialog title={props.featureProperties.name} onClose={props.handleClose} button={[{onClick:onAddLink, label:'Add a new link'}, {onClick:saveLinks, label:'Save'}]}>
        <Container onDrop={ e => {
          onDrop(e);
        }}>
          {
            items.map((item, index) =>   
              <Draggable key={`item-${index}`}>
                <div style={{display:'flex', alignItems: 'center'}}>
                  <div style={{width:'90%'}}>
                    <TextField required style={{width:'100%'}} key={`url-${index}`} label="URL" value={item.url} onChange={(e)=>handleChange(e,index,'url')} />
                    {item.image === true && <img src={item.url} style={{maxHeight: '67vh'}}></img>}
                    <TextField label="Description" multiline key={`desc-${index}`} value={item.desc || ''} onChange={(e) => handleChange(e,index,'desc')} style={{width:'100%'}} minRows={1} placeholder="Here you can add a description for this hyperlink" />
                    <TextField label="Description in local language" multiline key={`desc-local-${index}`} value={item.local_desc || ''} onChange={(e) => handleChange(e,index,'local_desc')} style={{width:'100%'}} minRows={1} placeholder="Here you can add a description in local language for this hyperlink" />
                  </div>
                  <IconButton key={`delete-btn-${index}`} onClick={() => onDelete(index)}><DeleteIcon></DeleteIcon></IconButton>
                </div>
                <Divider style={{marginTop:'10px'}}></Divider>
              </Draggable>
            )
          }
          </Container>
      </GeneralDialog>
  );
}
