import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 'small',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function KeyValueTable(props) {
  
  const rows = props.rows;

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => {
            
            let cellValue = row.value; //here you get an object after editing code list values

            if (cellValue && typeof cellValue === 'object') {
              cellValue = cellValue.value;
            }

            return (<StyledTableRow key={row.key}>
              <StyledTableCell style={{fontWeight: 'bolder'}} component="th" scope="row">
                {row.label}
              </StyledTableCell>
              <StyledTableCell align="left">{cellValue}</StyledTableCell>
            </StyledTableRow>)
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
