import L from 'leaflet';

import React from 'react';

import KeyValueTable from './components/KeyValueTable'

import { renderToString } from 'react-dom/server'
import ReactDOM from 'react-dom'

import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';
import TrashIcon from '@material-ui/icons/Delete';

//image exif orientation change: find . -type f -name "*.jpg" -exec exiftran -ai "{}" \; #semicolon is a must

var utils={};

utils.jsonParse = json => {
    if (typeof json !== 'object') {
        return JSON.parse(json);
    }

    return json;
}

utils.getLocalLanguage = (country) => {
    const langMap = {
        'AUSTRIA':'de',
        'BOSNIA AND HERZEGOVINA': 'bs',
        'BULGARIA':'bg',
        'CROATIA':'hr',
        'GERMANY':'de',
        'HUNGARY':'hu',
        'ROMANIA':'ro',
        'SERBIA':'sr',
        'SLOVENIA':'sl'
    };

    return langMap[country];
}

utils.hstore2object = function(hstore) {
    if (!hstore) return hstore;
    hstore = hstore.substring(1, hstore.length-1);
    let r = hstore.split('", "');
    return r.reduce((acc,cval) => {
        let a = cval.split('"=>"');
        if (a.length===2) {
            acc[a[0]] = a[1];
        }
        return acc;
    }, {});
}

utils.getQueryParameter = (variable) => {
    var query = window.location.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    }
  }

utils.applyDrag = (arr, dragResult) => {
    const { removedIndex, addedIndex, payload } = dragResult;
    if (removedIndex === null && addedIndex === null) return arr;
  
    const result = [...arr];
    let itemToAdd = payload;
  
    if (removedIndex !== null) {
      itemToAdd = result.splice(removedIndex, 1)[0];
    }
  
    if (addedIndex !== null) {
      result.splice(addedIndex, 0, itemToAdd);
    }
  
    return result;
  };

var mapUtils = {};

function onEachFeature(feature, layer, onFeatureClicked) {
    // does this feature have a property named popupContent?
    //layer.bindPopup('');
    layer.on({
        click: e=>{
            onFeatureClicked(e.latlng, feature.properties.id, layer)
            //console.log(e, layer, feature)
        }
    })
}

/* https://gist.github.com/geog4046instructor/80ee78db60862ede74eacba220809b64
 * Create a custom icon to use with a GeoJSON layer instead of the default blue
 * marker. This snippet assumes the map object (map) and GeoJSON object
 * (myLayerData) have already been declared.
 */

// replace Leaflet's default blue marker with a custom icon
function createCustomIcon (feature, latlng, iconImg) {

    const props = {};
    
    if (iconImg) {
        props.icon = L.icon({
            iconUrl: './icons/'+iconImg
            //shadowUrl: 'my-icon.png',
            /*iconSize:     [25, 25], // width and height of the image in pixels
            shadowSize:   [35, 20], // width, height of optional shadow image
            iconAnchor:   [12, 12], // point of the icon which will correspond to marker's location
            shadowAnchor: [12, 6],  // anchor point of the shadow. should be offset
            popupAnchor:  [0, 0] // point from which the popup should open relative to the iconAnchor*/
        });
    }

    return L.marker(latlng, props);
  }

mapUtils.add = (map, data, iconImg, props, onFeatureClicked) => {

    const {lineColor, lineWidth, dashArray, opacity} = props;

    let options = {
        pointToLayer: (feature, latlng) => createCustomIcon(feature, latlng, iconImg),
        filter: function(feature, layer) {
            return true;
        },
        onEachFeature: (feature, layer) => onEachFeature(feature, layer, onFeatureClicked)
    };

    options.style = {};

    if (lineColor) {
        options.style.color = lineColor;
    }

    if (lineWidth) {
        options.style.weight = lineWidth;
    }

    if (dashArray) {
        options.style.dashArray = dashArray;
    }

    if (opacity) {
        options.style.opacity = opacity;
    }
    
    const geojsonLayer = [];

    data.map(d => {
        const feature = JSON.parse(d.g);
        delete d.g;
        delete d.geom;
        delete d.date_record_created;
        delete d.date_record_modified;
        
        feature.properties = Object.assign(d);
        geojsonLayer.push(feature);
    })

    let layer = L.geoJSON(geojsonLayer, options);

    layer.addTo(map);

    return layer;

    /*
    
    var vals = Object.values(data).map((rec)=>JSON.parse(rec.value.value))
    console.log(vals)
    
    */
}

mapUtils.openPopup = (map, latlng, featureProperties, assocAttributes, callbacks, layer) => {
    const entries = [];
    let nameEntry = null;
    let url = null;

    console.log('mapUtils.openPopup', featureProperties, assocAttributes)

    let popup = null;
    
    Object.entries(featureProperties).map(e => {

        const attributeKey = e[0];

        if (['country_row_id', 'layer_key', 'images', 'id', 'links','_locales','_local_lang','_locale_language','g'].indexOf(attributeKey) !== -1) return;
        
        if (attributeKey === 'name') {
            nameEntry = e;
        }
        else if (attributeKey === 'URL') {
            if (e[1]) {
                url = e[1];
            }
        }
        else {
            e[0] =  (assocAttributes[attributeKey] && assocAttributes[attributeKey]['en']) || attributeKey;
            entries.push(e);
        }

        e.push(attributeKey);

    });

    if (nameEntry) {    //put feature name to the first place in table
        nameEntry[0] = assocAttributes[nameEntry[0]]['en'];
        entries.unshift(nameEntry);
    }
    
    let images = featureProperties.images = Array.isArray(featureProperties.images) ? featureProperties.images : JSON.parse(featureProperties.images);

    if (!Array.isArray(images)) {
        images = featureProperties.images = [];
    }

    let links = featureProperties.links = Array.isArray(featureProperties.links) ? featureProperties.links : JSON.parse(featureProperties.links);

    if (!Array.isArray(links)) {
        links = featureProperties.links = [];
    }
    
    const divTooltip = document.createElement("div");

    const adminKey = sessionStorage.getItem('adminKey');

    renderPopupDiv(entries);

    function renderPopupDiv(entries, _locales={}) {

        const tableKeyValues = [];

        const translatableTableKeyValues = [];

        const lang = utils.getLocalLanguage(featureProperties.country);

        const selectedLanguage = sessionStorage.getItem('language');

        entries.map((e,inx) => {
            const key = e[e.length-1];

            let value = e[1];

            if (!adminKey) {
                
                if (selectedLanguage && selectedLanguage === utils.getLocalLanguage(featureProperties.country)) {
                    //show translations
                    if (featureProperties._locales && assocAttributes[key]) {
                        if (assocAttributes[key].dataType === 'text') {
                            const locales = utils.jsonParse(featureProperties._locales);
                            if (locales[key]) {
                                value = locales[key];
                            }
                        }
                    }
                }
            }

            if (assocAttributes[key] && assocAttributes[key].dataType === 'code_list') {
                if (selectedLanguage === 'en' || (selectedLanguage && selectedLanguage === utils.getLocalLanguage(featureProperties.country))) {
                    if (value && typeof value === 'object') {
                        value = value.key;
                    }

                    const item = assocAttributes[key].values.find(item => item.key === value);
                    value = (item && (item.value || item.key)) || value || '';
                }
                
            }

            const a = assocAttributes[key];

            tableKeyValues.push({
                key: key,
                label: adminKey ? <a key={inx} onClick={()=>callbacks.onEditAttributes(featureProperties, e, renderPopupDiv, entries)} href="#">{a.t || a.key}</a> : (a.t || a.key),
                value: value
            });

            if (adminKey && assocAttributes[key] && assocAttributes[key].dataType === 'text') {
                const e_locales = e.map(item => item);

                e_locales[1] = (featureProperties._locales && utils.jsonParse(featureProperties._locales)[key]) || e[1]; 

                translatableTableKeyValues.push({
                    key: key,
                    label: <a key={'_t'+inx} onClick={()=>callbacks.onEditAttributes(featureProperties, {e:e_locales, lang}, renderPopupDiv, entries)} href="#">{(a.t || a.key)}</a>,
                    value: e_locales[1]
                }); 
            }
        });

        ReactDOM.render(<div>
            <div>
                {(adminKey || (images && images.length>0)) && <IconButton onClick={() => callbacks.onImageDialogIconClicked && callbacks.onImageDialogIconClicked(featureProperties)}>
                    <PhotoCameraIcon></PhotoCameraIcon>
                </IconButton>
                }
                {(adminKey || (links && links.length>0)) && <IconButton onClick={() => callbacks.onLinkDialogIconClicked && callbacks.onLinkDialogIconClicked(featureProperties)}>
                    <LinkIcon></LinkIcon>
                </IconButton>
                }
                {adminKey && <IconButton onClick={() => callbacks.onDeleteFeature && callbacks.onDeleteFeature(featureProperties, layer, popup)}>
                    <TrashIcon></TrashIcon>
                </IconButton>
                }
            </div>
            <div style={{overflowY: 'auto', maxHeight: '518px'}}>
                <KeyValueTable rows={tableKeyValues} />
                {adminKey && <h4>Translation to local language ({lang}) </h4>}
                {adminKey && <KeyValueTable rows={translatableTableKeyValues} />}
                <div>
                    
                </div>
            </div>

        </div>, divTooltip);
    }

    if (url) {
        //popup = popup + '<br>' + `<a href=${url} target="_blank">External link</a>`;
    }

    popup = L.popup({
        maxWidth : 560,
        maxHeight: 560
    }).setLatLng(latlng)
    .setContent(divTooltip)
    .openOn(map);
}

export default utils;
export var mapUtils=mapUtils;
