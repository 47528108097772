import React, { Component } from 'react';
import './App.css';

import adminLogo from './admin.svg';

import Map from './components/Map'
import MapDrawer from './components/DrawerComponent'
import DrawerToggler from './components/DrawerToggler';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CssBaseline from '@material-ui/core/CssBaseline';

import axios from 'axios'; 
import s from './settings';
import LayerSelector from './components/LayerSelector';

import AddSpatialFeature from './components/AddSpatialFeature';
import globals from './globals';

const apiServer = process.env.NODE_ENV === 'production' ? 'server' : 'local';

var leafletMap = null;

axios.defaults.baseURL = s[apiServer].apiUrl

class App extends Component {

  state = {
    addSpatialFeature: false,
    addedSpatialFeature: null,
    translations: null
  }

  setMapReference(_map) {
    leafletMap = _map;
  }

  onLayerCreate(e) {
    this.setState({addSpatialFeature: e});
  }

  async componentDidMount() {
    const lang = sessionStorage.getItem('language');
    const res = await axios.get('/translations'+(lang ? `/${lang}` :''));
      const translations = {};
      res.data.map(r=>{
        translations[r.key] = r.translation;
      });
      globals.translations = translations;
      this.setState({translations: translations});
  }


  render() {
    return (
      <div className="App">
        {this.state.addSpatialFeature && <AddSpatialFeature onSpatialFeatureAdded = {(addedSpatialFeature)=>this.setState({addedSpatialFeature: addedSpatialFeature})} item={this.state.addSpatialFeature} onClose={()=>this.setState({addSpatialFeature: false})}></AddSpatialFeature>}
        <CssBaseline></CssBaseline>
        {sessionStorage.getItem('adminKey') && <img src={adminLogo} style={{position:'fixed', zIndex:10000}} alt="logo" />}
        <MapDrawer
          translations={this.state.translations}
          drawerContent={[<LayerSelector
            translations={this.state.translations}
            addedSpatialFeature = {this.state.addedSpatialFeature}
            resetAddedSpatialFeature = {() => this.setState({addedSpatialFeature: null})}
            leafletMap = {() => leafletMap}
            key="layer-selector"
          ></LayerSelector>]}
        >
          <Map onLayerCreate = {e=>this.onLayerCreate(e)} setReference={this.setMapReference}>
            <DrawerToggler>
              <MoreHorizIcon></MoreHorizIcon>
            </DrawerToggler>
          </Map>
        </MapDrawer>
      </div>
    );
  }
}

export default App;
