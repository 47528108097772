import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SimpleTabs from './Tabs';
import ShareDialog from './ShareDialog';

import SettingsDialog from './SettingsDialog';
import globals from '../globals';

const drawerWidth = 400
const breakPointMax = '@media (max-width:' + drawerWidth + 'px)'
const breakPointMin = '@media (min-width:' + (drawerWidth+1) + 'px)'

const styles = theme => ({
  drawerPaper: {
    [breakPointMax]: {
      width: "100%"
    },
    [breakPointMin]: {
      width: drawerWidth
    },
    top: 0,
    paddingTop: theme.spacing(2),
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.3);"
  },

  paddingLeft: {
    paddingLeft: theme.spacing(1)
  },

  drawerContent: {
    top: theme.mixins.toolbar['@media (min-width:600px)'].minHeight,
    width: '100%'
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  
  box: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  boxHeader: {
    flex: '0 1 auto',
    position: 'inherit'
    /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
  },
  boxContent: {
    flex: '1 1 auto',
    display: 'flex',
    overflowY: 'auto'
  },
  boxFooter: {
    flex: '0 1 40px'
  }
});

class DrawerComponent extends React.Component {
  state = {
    open: true,
  };

  toggleDrawerState = (_open) => {
    let open = _open !== undefined ? _open : !this.state.open
    this.setState({ open: open });
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.box}>
            <div className={classes.boxHeader}>
              <a href={window.location.origin}>
                <img style={{width:'85%', marginLeft:'20px'}} src='./poza-proiect-ISTER-descriere-2.png' />
              </a>
              <Typography variant="h6" className={classes.paddingLeft} gutterBottom>
                
              </Typography>

              <IconButton aria-label="Close" className={classes.closeButton} onClick={() => this.toggleDrawerState(false)}>
                <CloseIcon />
              </IconButton>
            </div>

            <div className={classes.boxContent}>
              <SimpleTabs
                notifyParent = {
                  (value) => {
                    if (value === 3) {
                      this.setState({settingsOpened: true}); //to refresh the initial state of input text boxes
                    }
                  }
                }
                tabs = {[
                  <Typography component="p" style={{"textAlign" : "justify","textJustify": "inter-word", "margin":"10px", "marginTop":"20px", "fontSize":"85%"}}>
                    {this.props.translations && this.props.translations['ui.about_msg'] || 'ui.about_msg'}
                  </Typography>,
                  this.props.drawerContent,
                  <ShareDialog translations={this.props.translations}></ShareDialog>,
                  <SettingsDialog opened={this.props.settingsOpened} translations={this.props.translations}></SettingsDialog>
                ]}
              />
            </div>
          </div>
        </Drawer>
        {
          React.Children.map(this.props.children, child => {
              return React.cloneElement(child, {'toggleDrawerState': this.toggleDrawerState});
          })
        }
      </div>
    );
  }
}

//a warning is shown if the prop isn't provided.
DrawerComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(DrawerComponent);