const globals = {
    map: null,
    filter: {},
    checked: {},
    spatialFilter: null,
    language:'en',
    translations: {},
    getTranslation: (key) => {
        return globals.translations[key] || key;
    }
}

export default globals;