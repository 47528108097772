import React from 'react';
import s from '../settings';
import utils from '../utils'

import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import AdminKeyDialog from './AdminKeyDialog'

import Translations from './Translations';
import globals from '../globals';

const apiServer = process.env.NODE_ENV === 'production' ? 'server' : 'local';

const photoStorage = s[apiServer].photoStorage;

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  

export default function SettingsDialog(props) { 
  const [language, setLanguage] = React.useState(sessionStorage.getItem('language') || 'en');
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const handleChange = (event) => {
    const lang = event.target.value;
    sessionStorage.setItem('language', lang);
    setLanguage(lang);
    window.location.reload();
  };

  const adminKey = sessionStorage.getItem('adminKey');

  return (
    <div style={{marginTop:'15px', marginLeft:'15px', marginRight:'15px'}}>
    <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">{globals.getTranslation('ui.language')}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          onChange={handleChange}
        >
          <MenuItem value={'bg'}>Български</MenuItem>
          <MenuItem value={'bs'}>Bosanski</MenuItem>
          <MenuItem value={'de'}>Deutsch</MenuItem>
          <MenuItem value={'en'}>English</MenuItem>
          <MenuItem value={'hr'}>Hrvatski</MenuItem>
          <MenuItem value={'hu'}>Magyar</MenuItem>
          <MenuItem value={'ro'}>Românește</MenuItem>
          <MenuItem value={'sl'}>Slovenščina</MenuItem>
          <MenuItem value={'sr'}>Cрпски</MenuItem>
        </Select>
      </FormControl><br/><br/>
      {
        adminKey ? 
      <Button style={{width:'100%'}} onClick = {() => {sessionStorage.removeItem('adminKey'); window.location.reload();}} variant="outlined" color="secondary">
        {globals.getTranslation('ui.leave_admin_mode')}
      </Button>
      :
      <Button style={{width:'100%'}} onClick = {() => setOpen(true)} variant="outlined" color="secondary">
        {globals.getTranslation('ui.enter_admin_mode')}
      </Button>
        }
      {open && <AdminKeyDialog></AdminKeyDialog>}

      {adminKey && <Translations translations={props.translations}></Translations>}
    </div>
  );
}
