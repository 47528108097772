import React from 'react';
import { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import SnackMsgBox from './SnackMsgBox';
import Portal from '@material-ui/core/Portal';
import s from '../settings';
import TextField from '@material-ui/core/TextField';
import {
  makeStyles
} from '@material-ui/core/styles';

import './css/translations.css';

import globals from '../globals';

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize:'small' 
  }
}));

export default function Translations(props) { 

  if (!props.translations) return null;

  const handleChange = (event) => {
    value[event.target.id] = changedValues[event.target.id] = event.target.value;
    setValue({...value});
    setChangedValues({...changedValues});
  };

  useEffect(() => {
    axios.get('/translations/en').then(res => {
      const _englishTranslations = {};

      res.data.map(d => {
        _englishTranslations[d.key] = d.translation;
      });

      const translations = props.translations || {};

      _englishTranslations && Object.entries(_englishTranslations).map(([key, en]) => {
          translations[key] = translations[key];
      });

      setEnglishTranslations(_englishTranslations);
      setValue(translations);
    })
  }, []); 

  const saveTranslations = () => {
    const adminKey = sessionStorage.getItem('adminKey');
    axios.post('/translations/' + sessionStorage.getItem('language') + '?key=' + adminKey, changedValues).then(res=> {
      setSaveStatusMsg('Translations were successfully saved.');
    });
    console.log(value);
  }

  const [value, setValue] = React.useState({});
  const [changedValues, setChangedValues] = React.useState({});
  const [saveStatusMsg, setSaveStatusMsg] = React.useState(null);
  const [englishTranslations, setEnglishTranslations] = React.useState({});
  const classes = useStyles();
  
  return (
    <div>
      {saveStatusMsg && <Portal><SnackMsgBox severity="success" onClose={()=>{setSaveStatusMsg(null);}} msg={saveStatusMsg}></SnackMsgBox></Portal>}
      <br></br>
      <Button style={{width:'100%'}} onClick = {saveTranslations} variant="outlined">
        {globals.getTranslation('ui.save_translations')}
      </Button>
    <div className="container" style={{marginTop:'15px', display: 'flex', 'flexDirection': 'column', height:'63vh', overflowY: 'auto'}}>
        {
          Object.entries(value).map(e => {
            
            return (<div className="item" key={e[0]}>
              <div className="english-text">{englishTranslations[e[0]]}</div>
            
            <TextField
              style={{width:'95%'}}
              InputProps={{classes}}
              id={e[0]}
              size="small"
              multiline
              maxRows={3}
              value={e[1]}
              onChange={handleChange}
              variant="outlined"
            />
            </div>)
          })
        }
    </div>
    </div>
  );
}
