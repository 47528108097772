import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListIcon from '@material-ui/icons/List';
import HomeIcon from '@material-ui/icons/Home';
import ShareIcon from '@material-ui/icons/Share';
import SettingsIcon from '@material-ui/icons/Settings';

import Box from '@material-ui/core/Box';

import LanguageMenu from './Menu';
import globals from '../globals';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{padding:'0px',paddingTop:'10px'}}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tab: {
        minWidth: 100,
        width: 100
    }
}));

export default function ScrollableTabsButtonForce(props) {
  const languageSelector = useRef(null);
  const classes = useStyles();
  const [value, setValue] = React.useState(1);
  const [languageMenuOpen, setLanguageMenuOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    /*newValue != 2 &&*/ setValue(newValue);
  };

  const handleLanguageClick = (event) => {
    setLanguageMenuOpen(true)
  }

  useEffect(()=>{
    props.notifyParent(value);
  },[value])

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab className={classes.tab} label={globals.getTranslation('ui.about')} icon={<HomeIcon />} {...a11yProps(0)} />
          <Tab className={classes.tab} label={globals.getTranslation('ui.layers')} icon={<ListIcon />} {...a11yProps(1)} />
          <Tab className={classes.tab} label={globals.getTranslation('ui.share')} icon={<ShareIcon />} {...a11yProps(2)} />
          <Tab className={classes.tab} label={globals.getTranslation('ui.settings')} icon={<SettingsIcon />} {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <div style={{display: value!==0 && 'none'}}>
      {
        props.tabs[0]
      }
      </div>
      <div style={{display: value!==1 && 'none'}}>
      {
        props.tabs[1]
      }
      </div>
      <div style={{display: value!==2 && 'none'}}>
      {
        props.tabs[2]
      }
      </div>
      <div style={{display: value!==3 && 'none'}}>
      {
        props.tabs[3]
      }
      </div>
    </div>
  );
}
