import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';
import LinkIcon from '@material-ui/icons/Link';

import Typography from '@material-ui/core/Typography';

import GeneralDialog from './GeneralDialog';
import ImageGallery from './ImageGallery';
import LinkGallery from './LinkGallery';

import globals from '../globals';

import utils from '../utils';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin:'10px'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold'
  },
  attributeName: {
    fontSize: 12,
    marginBottom: '-3px'
  },
  attributeValue: {
    fontSize: 13,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent: {
    maxWidth:'20vw',
    width:'20vw'
  }
});

export default function SimpleCard(props) {
  const classes = useStyles();

  const [openDialog, setOpenDialog] = React.useState(null);

  if (!props.selectedLayerData) return null;

  if (props.selectedLayerData.length === 0) return null;
    
  const attributes = props.attributes;

  const language = globals.language;
  
  const onRowClicked = (row, key) => {
    if (!row[key]) return;
    if (!Array.isArray(row[key])) {
      row[key] = JSON.parse(row[key]);
    }
    setOpenDialog({
      name:row.name,
      [key]: row[key]
    });
  }

  const selectedLanguage = sessionStorage.getItem('language');

  props.selectedLayerData.map((row,rinx)=>{
    const country = row.country;
    Object.keys(row).map((key,cinx) => {
      //show translations
      let value = row[key];
      if (selectedLanguage && selectedLanguage === utils.getLocalLanguage(country)) {
        if (row._locales && attributes[key]) {
          if (attributes[key].dataType === 'text') {
              const locales = utils.jsonParse(row._locales);
              if (locales[key]) {
                  value = locales[key];
              }
          }
          else if (attributes[key].dataType === 'code_list') {
            
              if (value && typeof value === 'object') {
                  value = value.key;
              }

              const item = attributes[key].values.find(item => item.key === value);
              value = (item && (item.value || item.key)) || value || '';           
          }
        }
      }
      row[key] = value;
    });
  });

  return (
    <div>
    {openDialog && <GeneralDialog onClose={()=>setOpenDialog(null)} title={openDialog.name}>
    {openDialog.images ? <ImageGallery localLanguage={utils.getLocalLanguage(openDialog.country)} images={openDialog.images}></ImageGallery> : <LinkGallery localLanguage={utils.getLocalLanguage(openDialog.country)} items={openDialog.links}></LinkGallery>}
  </GeneralDialog>}
    
    <div style={{display: 'flex', maxWidth:'100%','flexWrap':'wrap','alignItems':'flex-start', 'justifyContent':'center'}}>
      {
      props.selectedLayerData.map((row,rinx)=>
      
      <Card key={'card-' + rinx} className={classes.root}>
      <CardContent className={classes.cardContent}>
        <div className={classes.title}>
          {row.name}
        </div>
        {
          Object.keys(row).map((key,cinx) => {
            return (['country_row_id', 'name', 'images', 'links'].indexOf(key) === -1 && row[key] && attributes[key] ?  
            <div key={'card-item-'+rinx+'-'+cinx}>
              <Typography className={classes.attributeName} color="textSecondary">
                {attributes[key].t||attributes[key].key}
              </Typography>
              <div className={classes.attributeValue}>
                {row[key]}
              </div>
            </div>
            
            :null)
          })
        }
      </CardContent>
      {
      (row.links || row.images) && <CardActions>
        {row.images && <IconButton onClick={()=>onRowClicked(row, 'images')} size="small"><PhotoCameraIcon></PhotoCameraIcon></IconButton>}
        {row.links && <IconButton onClick={()=>onRowClicked(row, 'links')} size="small"><LinkIcon></LinkIcon></IconButton>}
      </CardActions>
      }
    </Card>
      
      )}
    </div>
    </div>
  );
}
