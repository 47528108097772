import React from 'react';
import Button from '@material-ui/core/Button';
import styles from './DrawerToggler.module.css'

class DrawerToggler extends React.Component {
  render() {
    return (
      <Button variant="contained" className={styles.button} onClick = {() => this.props.toggleDrawerState(true)}>
        {this.props.children}
      </Button>
    )
  }
}

DrawerToggler.defaultProps = {
  leafletControl: true,
  name: 'DrawerToggler',
  isOpenedInitially: true
}

export default DrawerToggler;
