import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import globals from '../globals';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();

  const rows = props.rows || [];

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="left"></StyledTableCell>
            <StyledTableCell align="right">{(props.columns && props.columns[0].label) || globals.getTranslation('ui.filter.Operator')}</StyledTableCell>
            <StyledTableCell align="right">{(props.columns && props.columns[0].label) || globals.getTranslation('ui.filter.Attribute')}</StyledTableCell>
            <StyledTableCell align="right">{(props.columns && props.columns[1].label) || globals.getTranslation('ui.filter.Condition')}</StyledTableCell>
            <StyledTableCell align="right">{(props.columns && props.columns[2].label) || globals.getTranslation('ui.filter.Value')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return (<StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                <div style={{"display": "flex"}}><DeleteIcon style={{cursor: 'pointer'}} onClick={() => props.onRemoveFilterDataRow(index) }></DeleteIcon></div>
              </StyledTableCell>
              <StyledTableCell align="right">{index===0 ? '' : 'AND'}</StyledTableCell>
              <StyledTableCell align="right">{(props.columns && row[props.columns[0].key]) || row.attribute}</StyledTableCell>
              <StyledTableCell align="right">{(props.columns && row[props.columns[1].key]) || row.condition}</StyledTableCell>
              <StyledTableCell align="right">{(props.columns && props.columns[2].values.find(item => item.id == row[props.columns[2].key]).key) || row.value}</StyledTableCell>
            </StyledTableRow>)
        })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}