import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import SaveIcon from '@material-ui/icons/SaveAlt';
import AttributeTable from './AttributeTable';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'sticky',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  if (!props.selectedLayerData) return null;

  console.log(props)

  return (
    <div>
      <Dialog 
        fullScreen 
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton color="inherit" onClick={props.handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit" className={classes.flex}>
                {props.label}
              </Typography>
              <IconButton download="ister-data.geojson" color="inherit" href={props.handleSave()} aria-label="Save">
                <SaveIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <AttributeTable attributes = {props.attributes} selectedLayerData={props.selectedLayerData}/>
      </Dialog>
    </div>
  );
}
