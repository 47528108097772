import React from 'react';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider'

export default function LinkDialog(props) {
  const selectedLanguage = sessionStorage.getItem('language');
    
    let local = false;
    if (selectedLanguage === props.localLanguage) {
      local = true;
    }
  return (
    <div>
       {
        props.items.map((item, index) => 
            <div key={`item-${index}`} style={{display: 'flex', flexDirection: 'column'}}>
                <a key={`url-${index}`} href={item.url} target='_blank'>{item.url}</a>
                {item.image === true && <img src={item.url} style={{maxHeight: '25vh', maxWidth:'fit-content'}}></img>}
                { item.desc && <div key={`desc-${index}`} style={{width:'100%'}}> 
                    {(local && item.local_desc) || item.desc}
                </div>}
                <Divider style={{marginTop:'10px'}}></Divider>
            </div>)
        }
    </div>
  );
}
