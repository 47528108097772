import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import Button from '@material-ui/core/Button';

import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    }
  }));

export default function GeneralDialog(props) {
    
    const classes = useStyles();

    let buttons = [];

    if (props.button) {
        buttons = Array.isArray(props.button) ? props.button : [props.button];
    }
    
    return <Dialog fullWidth maxWidth='md' open={true} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            {props.title}
            <IconButton onClick={props.onClose} className={classes.closeButton}><CloseIcon></CloseIcon></IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
            {props.children}
        </DialogContent>
        {
            props.button && <DialogActions>
                {
                    buttons.map((button,inx)=>
                        
                            <Button key = {`b${inx}`} onClick={button.onClick} color="primary">
                                {button.label}
                            </Button>
                        
                    )
                }
            </DialogActions> 
            
        }
      </Dialog>
}