import React from 'react';
import TextField from '@material-ui/core/TextField';
import GeneralDialog from './GeneralDialog';
import axios from 'axios';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import ComboBox from './ComboBox';


export default function AttributeEditor(props) {
// <AttributeEditor onSaveComplete={this.onSaveComplete} attributeProperties = {this.assocAttributes} featureProperties={this.state.attributeEditorDialog.featureProperties} keyValue = {this.state.attributeEditorDialog.keyValue}></AttributeEditor>

  let keyValue = props.keyValue;

  if (keyValue && keyValue.e) {
    keyValue = keyValue.e;
  }

  const [value, setValue] = React.useState(keyValue[1]);

  const attributeKey = keyValue[keyValue.length-1];
  const p = props.attributeProperties[attributeKey];

  const adminKey = sessionStorage.getItem('adminKey');

  let component = null;

  if (p.dataType  === 'text') {
      component = <TextField
        onChange = {e => onChange(e.target.value)}
        label={p.t || p.en}
        id="filled-size-small"
        defaultValue={keyValue[1]}
        variant="filled"
        size="small"
        style={{width:'100%'}}
        />
  }
  else if (p.dataType  === 'integer') {
    component = <TextField
      type={"number"}
      onChange = {e => onChange(e.target.value)}
      label={p.t || p.en}
      defaultValue={keyValue[1]}
      variant="filled"
      size="small"
      style={{width:'100%'}}
      />
  }
  else if (p.dataType === 'code_list') {
    component = <ComboBox value={keyValue[1]} onChange={(value) => onChange(value)} attributeDefinition = {p} label={p.t || p.en}></ComboBox>
  }
  else if (p.dataType === 'date') {
    component = <DatePicker inline selected={keyValue[1] ? new Date(keyValue[1]):null} onChange={(value) => onChange(value)} />
  }
  else {
    return null;
  }

  const onSave = () => {
    const fid = props.featureProperties.id;
    if (!fid) return;

    let _value = value;

    if (p.dataType === 'date') {
      //https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
      const offset = value.getTimezoneOffset();
      _value = new Date(value.getTime() - (offset*60*1000));
      _value = _value.toISOString().split('T')[0];
    }

    axios.post('/update_attribute/'+fid+'?key='+adminKey, {
      key: attributeKey,
      lang: (props.keyValue && props.keyValue.lang) || null,
      value: _value,
      tname: props.tname
    }).then(res=> {
      props.onSaveComplete(attributeKey,fid,_value);
    });
  }

  const onChange = (_value) => {
    console.log('onChange', _value)
    setValue(_value);
  }

  return (
    <GeneralDialog onClose={props.onClose} style="min-height:" title="Attribute editor" button={[
      {label: 'Save', onClick: onSave}
    ]}>
      {component}
    </GeneralDialog>
  );
}
