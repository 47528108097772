import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import globals from '../globals';
import ImageDialog from './ImageDialog';
import s from '../settings';
import utils from '../utils'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const apiServer = process.env.NODE_ENV === 'production' ? 'server' : 'local';

const photoStorage = s[apiServer].photoStorage;

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  }
}));

export default function ShareDialog(props) { 
  const [open, setOpen] = React.useState(false);
  const [shareId, setShareId] = React.useState(null);
  const [imageDialogOpen, setImageDialogOpen] = React.useState(false);
  const [info, setInfo] = React.useState('');
  const [publish, setPublish] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [img, setImg] = React.useState(null);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  }

  const shareData = () => {
    //https://stackoverflow.com/questions/53574614/multiple-calls-to-state-updater-from-usestate-in-component-causes-multiple-re-re
    //https://www.codelime.blog/react-context-vs-global-values/
    axios.post('/share',{
      'filter': globals.filter,
      'checked': globals.checked,
      'publish': publish,
      'map': {
        center: globals.map.getCenter(),
        zoom: globals.map.getZoom()
      },
      'spatial_filter': globals.spatialFilter,
      info: info,
      title: title,
      img: img
    }).then(res=> {
      setShareId(res.data);
      setOpen(true);
    });
  }

  const getLink = () => window.location.origin+'?share='+shareId;

  const onImageSaveComplete = (key, id, data) => {
    const inx = data.md5_name.lastIndexOf('.');
    if (inx === -1) return;

    const fname = '_thmb_' + data.md5_name.substring(0,inx) + '.jpg';

    console.log('fname', fname)

    setImg(fname);
  }

  const adminKey = sessionStorage.getItem('adminKey');

  return (
    <div style={{marginTop:'15px', marginLeft:'15px', marginRight:'15px'}}>
      {imageDialogOpen && <ImageDialog onSaveComplete={onImageSaveComplete} handleClose={()=>{setImageDialogOpen(false)}}></ImageDialog>}
      {globals.getTranslation('ui.current_view')}<br></br><br></br>
        {adminKey &&
        <React.Fragment>
          {img && <img style={{maxWidth:'100%'}} src={photoStorage + img}></img>}
          <TextField
            style={{width:'100%'}}
            placeholder={globals.getTranslation('ui.Please provide a title for your view')}
            value = {title}
            required
            onChange = {(e) => setTitle(e.target.value)}
          />
          <Button onClick={()=>setImageDialogOpen(true)} color="primary">
                {globals.getTranslation('ui.Upload title image')}
          </Button>
          <TextField
            style={{width:'100%'}}
            variant="outlined"
            placeholder={globals.getTranslation("ui.You can (optionally) provide a description for your view")}
            multiline
            rows={4}
            value = {info}
            onChange = {(e) => setInfo(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox checked={publish} onChange={e=>setPublish(e.target.checked)} name="publish" />}
            label={globals.getTranslation("ui.Publish link in ISTER Atlas gallery")}
          />
        </React.Fragment>
      }
      <br></br>
          <Button style={{float:'right'}} onClick={shareData} color="primary">
            {globals.getTranslation("ui.Get a link")}
          </Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={open}
            //autoHideDuration={6000}
            onClose={handleClose}
            message={getLink()}
            action={
              <React.Fragment>
                <Button color="secondary" size="small" onClick={()=>navigator.clipboard.writeText(getLink())}>
                  Copy
                </Button>
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          />
    </div>
  );
}
