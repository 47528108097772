import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useRef } from 'react';

import axios from 'axios';
import s from '../settings';

const apiServer = process.env.NODE_ENV === 'production' ? 'server' : 'local';

const apiUrl = s[apiServer].apiUrl;

export default function FormDialog() {
  const [open, setOpen] = React.useState(true);

  const elementRef = useRef();

  const handleClose = (checkKey = false) => {

    if (checkKey) {
      //
      const key = elementRef.current.value;

      axios.post('/check_admin_key', {
        key: key
      }).then(res=> {
        if (res.data && res.data.key === key) {
          sessionStorage.setItem('adminKey', key);
          sessionStorage.setItem('editDataTable', res.data.dataTable);
          sessionStorage.setItem('editMap', res.data.map);
          setOpen(false);
          window.location.reload();
        }
        else {
          alert('You have entered wrong admin key.');
        }
      });
    }
    else {
      setOpen(false);
    }
  };

  return (
    <div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Enter admin mode key</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Admin mode allows editing spatial feature images and links.
          </DialogContentText>
          <TextField
            inputRef={elementRef}
            autoFocus
            margin="dense"
            id="name"
            label="Admin mode key"
            type="password"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>handleClose(true)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
